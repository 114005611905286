import React from "react";
import Section from "../Section";
import Spacing from "../Spacing";
import DotLoader from "react-spinners/PuffLoader";
import axios from "axios";
import { toast } from "react-toastify";
export default function ContactForm() {
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const handleForm = async (e) => {
    e.preventDefault();
    if (!name || !email || !message || !subject) {
      return toast.error("Please fill in all fields. ", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setLoading(true);
    try {
      let data = JSON.stringify({
        name: name,
        email: email,
        message: message,
        subject: subject,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.wheelballtoken.com/api/v1/help/contact",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer wbtuser'
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
          toast.success("Succsessfuly", {
            position: toast.POSITION.TOP_CENTER,
          });
        })
        .catch((error) => {
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
          setLoading(false);
          toast.error("Someting Went Wrong ", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (error) {
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      setLoading(false);
      toast.error("Someting Went Wrong ", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  return (
    <form>
      <Section className="row">
        <Section className="col-lg-6">
          <Section className="cs-form_field_wrap">
            <label>Your Name</label>
            <input
              type="text"
              className="cs-form_field"
              onChange={(e) => setName(e.target.value)}
            />
          </Section>
          <Spacing lg="30" md="30" />
        </Section>
        <Section className="col-lg-6">
          <Section className="cs-form_field_wrap">
            <label>Your Email</label>
            <input
              type="text"
              className="cs-form_field"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Section>
          <Spacing lg="30" md="30" />
        </Section>
        <Section className="col-lg-12">
          <Section className="cs-form_field_wrap">
            <label>Subject</label>
            <input
              type="text"
              className="cs-form_field"
              onChange={(e) => setSubject(e.target.value)}
            />
          </Section>
          <Spacing lg="30" md="30" />
        </Section>
        <Section className="col-lg-12">
          <Section className="cs-form_field_wrap">
            <label>Message</label>
            <textarea
              className="cs-form_field"
              cols="30"
              rows="5 "
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </Section>
          <Spacing lg="25" md="25" />
        </Section>
        <Section className="col-lg-12">
          <button
            className="cs-btn cs-btn_filed cs-accent_btn"
            onClick={(e) => handleForm(e)}
            disabled={loading}
          >
            {loading ? (
              <DotLoader color="#1876f2" loading={loading} size={30} />
            ) : (
              "Submit "
            )}
          </button>
        </Section>
      </Section>
    </form>
  );
}
