import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Section from "../Section";
import { isMetaMaskInstalled, connectWallet } from "../../utils/web3functions";
import { toast } from "react-toastify";
import { walletContext } from "../../contexts/walletContext";
import Button from "../Button";
import Spacing from "../Spacing";
import axios from "axios";
import DotLoader from "react-spinners/PuffLoader";

const SubsCard = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    if (email === "") return toast.error("Please enter your email");
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.wheelballtoken.com/api/v1/newlester",
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer wbtuser'
          },
        }
      );
      if (response.status === 201) {
        toast.success("Thank you for subscribing!");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return toast.error("someting went wrong");
    }
  };
  return (
    <Section className="cs-wallet_secton text-center">
      <Section className="col-lg-12">
        <Section className="cs-form_field_wrap">
          <label>Subscribe</label>
          <input
            type="text"
            className="cs-form_field"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Section>
        <Spacing lg="30" md="30" />
      </Section>

      <Section className="col-lg-12">
        <button
          className="cs-btn cs-btn_filed cs-accent_btn"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <DotLoader color="#1876f2" loading={loading} size={30} />
          ) : (
            "Subscribe"
          )}
        </button>
      </Section>
    </Section>
  );
};

export default SubsCard;
