import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Accordion from "../Accordion";
import Button from "../Button";
import Card from "../Card";
import ContactForm from "../ContactForm";
import ContactInfo from "../ContactInfo";
import FunFact from "../FunFact";
import Hero from "../Hero";
import IconBox from "../IconBox";
import Modal from "../Modal";
import Section from "../Section";
import SectionHeading from "../SectionHeading";
import ImageBoxSlider from "../Slider/ImageBoxSlider";
import RoadmapSlider from "../Slider/RoadmapSlider";
import Spacing from "../Spacing";
import Team from "../Team";
import pdf from "../assets/wbt3whitepaper.md";
import { Link } from "react-router-dom";


export default function Home() {
  pageTitle("Wheel Ball Game");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const accordionData1 = [
    {
      question: "What is Wheel Ball?",
      answer:
        "Wheel Ball is a fast-paced, hyper-casual mobile game offering an engaging gameplay experience, ready for the Metaverse. Players earn tokens by advancing through levels and watching in-game ads.",
    },
    {
      question: "What is a seed sale, and how can I participate?",
      answer:
        "A seed sale is a special sale event where early supporters can purchase tokens during the early phase of the project. To participate, follow the instructions on our game’s website.",
    },
    {
      question: "How is Wheel Ball played?",
      answer:
        "Wheel Ball is played with simple tap and drag motions for seamless control. Players progress to new levels by overcoming obstacles and completing challenges.",
    },
    {
      question: "What do I need to do to earn tokens?",
      answer:
        "You earn tokens by completing levels and watching in-game ads. Watching ads provides a reward boost, increasing your earnings.",
    },
  ];
  const accordionData2 = [
    {
      question: "What is a reward boost, and how does it work?",
      answer:
        "A reward boost is a system that increases your in-game rewards for watching ads. It creates a multiplier effect that temporarily boosts your token earnings.",
    },
    {
      question: "What are NFT characters, and what can I do with them?",
      answer:
        "NFT characters are unique, ownable digital assets in the game. They provide in-game advantages and can be bought and sold on the NFT marketplace.",
    },
    {
      question: "What is the NFT marketplace, and what can I find there?",
      answer:
        "The NFT marketplace is a platform where players can trade NFT characters and other in-game assets. This marketplace facilitates player participation in the game economy and trade of their assets.",
    },
    {
      question: "Do I need to pay to play the game?",
      answer:
        "No, Wheel Ball is free to play, with optional in-game content available for purchase. You can gain in-game advantages by purchasing tokens during the seed sale.",
    },
  ];
  return (
    <>
      <Hero
        title=" Wheel Ball
         <br />  Game"
        mintNumber="0"
        mintMax="725"
        mintPrice="0.05"
        mintDeathLine="25 January 2024"
        bgUrl="/images/bg_home.png"
        heroImageUrl="/images/hero_img.svg"
        animatedUrl="/images/hero_img_sm.png"
        overlay="0.3"
        bgOpacity="1"
      />
      <Section tag="section" id="about">
        <Spacing lg="100" md="70" />
        <Section className="container">
          <Section className="row align-content-center">
            <Section className="col-lg-6">
              <Section className="cs-right_space_40">
                <SectionHeading title="About Us" subtitle="Our Story" />
                <Spacing lg="20" md="20" />
                <Section tag="p">
                  WheelBall is a revolutionary web-based P2E game launched on
                  Binance Smart Chain and available to users. The core of the game
                  revolves around rewarding players with WBT coins after passing
                  the levels. They can hire additional characters, create unique
                  weapons, and reshape these weapons to increase their overall
                  power. Players can also trade their characters and weapons.
                  open marketplace. They can also stake their WBT earnings and
                  receive additional WBT as rewards.
                </Section>
                <Section tag="p">
                </Section>
                <Spacing lg="25" md="15" />
                <Section className="cs-btn_group">
                  {/* <Modal modalType="mint" btnText="Connect" /> */}
                  <Link to="https://docs.wheelballtoken.com" target="_blank" className="cs-btn cs-color1">
                    Whitepaper
                  </Link>
                </Section>
              </Section>
              <Spacing lg="0" md="40" />
            </Section>
            <Section className="col-lg-6">
              <img src="/images/resim_nft//giris.png" alt="About" />
            </Section>
          </Section>
        </Section>
        <Spacing lg="100" md="70" />
      </Section>
      <Section className="container">
        <Section className="row">
          <Section className="col-lg-4">
            <IconBox
              title="Gaming NFTS"
              subtitle="Each NFT not only reflects your skills and style but also leaves a lasting imprint as a testament to the victories and adventures you've earned in the Wheel Ball arenas. 
              These valuable in-game assets are fully tradable and hold real-world value, representing not just a virtual achievement but a tangible investment.
          
              Step into the world of Wheel Ball's NFTs today to write your own legend and take the game's future to the next level."
            >
              01
            </IconBox>
            <Spacing lg="25" md="25" />
          </Section>
          <Section className="col-lg-4">
            <IconBox
              title="Play To Earn"
              subtitle="In the Wheel Ball universe, each level completion and challenge rewards you not just with scores, but with actual prizes. 
              You earn tokens with every interaction while playing, tokens that are a testament to your success.
              
              Increase your earnings by watching ads and multiplying your rewards with boost multipliers. This allows for rapid progression and the chance to earn more tokens.
              
              Wheel Ball makes every moment of play valuable. Join now, play, earn, and carve your legend in Wheel Ball!"
            >
              02
            </IconBox>
            <Spacing lg="25" md="25" />
          </Section>
          <Section className="col-lg-4">
            <IconBox
              iconNumber="02"
              title="Metaverse Ready"
              subtitle="Dive into a realm where gaming leaps off the screen and into a fully immersive experience. 
              Wheel Ball is not just metaverse-ready—it’s a pioneering adventure that transforms every play into a step into vast virtual worlds. 
              Get ready to shape your legacy in a space where the only limit is your imagination.
              
              Join the vanguard of the metaverse revolution with Wheel Ball. Your journey starts now!"
            >
              03
            </IconBox>
            <Spacing lg="25" md="25" />
          </Section>
        </Section>
      </Section>
      <Spacing lg="70" md="45" />
      <ImageBoxSlider />
      <Spacing lg="90" md="65" />
      {/* <Section className="container">
        <Section className="row">
          <Section className="col-lg-3 col-6">
            <FunFact 
              number='365'
              title='Total Items'
            />
            <Spacing lg='25' md='25'/>
          </Section>
          <Section className="col-lg-3 col-6">
            <FunFact 
              number='1125'
              title='Total Owners'
            />
            <Spacing lg='25' md='25'/>
          </Section>
          <Section className="col-lg-3 col-6">
            <FunFact 
              number='1.72'
              title='Floor Price'
            />
            <Spacing lg='25' md='25'/>
          </Section>
          <Section className="col-lg-3 col-6">
            <FunFact 
              number='42.2'
              title='Volume Traded'
            />
            <Spacing lg='25' md='25'/>
          </Section>
        </Section>
      </Section> */}
      <Spacing lg="65" md="35" />
      <Section className="container">
        <SectionHeading title="Buy Token Techniques" subtitle="Simple Steps" />
        <Spacing lg="50" md="30" />
        <Section className="row">
          <Section className="col-xl-3 col-md-6">
            <Card title="Connect Wallet" emoji="👋" />
            <Spacing lg="25" md="25" />
          </Section>
          <Section className="col-xl-3 col-md-6">
            <Card title="Select Your Quantity" emoji="👉" />
            <Spacing lg="25" md="25" />
          </Section>
          <Section className="col-xl-3 col-md-6">
            <Card title="Transaction Confirm" emoji="👍" />
            <Spacing lg="25" md="25" />
          </Section>
          <Section className="col-xl-3 col-md-6">
            <Card title="Receive Your Tokens" emoji="🙂" />
            <Spacing lg="25" md="25" />
          </Section>
        </Section>
      </Section>
      <Section tag="section" id="roadmap">
        <Spacing lg="70" md="40" />
        <Section className="container">
          <SectionHeading title="Roadmap" subtitle="Our Goals" />
          <Spacing lg="75" md="30" />
          <Section className="cs-accent_seperator_1 cs-accent_color"></Section>
          <RoadmapSlider />
        </Section>
      </Section>
      <Section id="team">
        <Spacing lg="70" md="40" />
        <Section className="container">
          <SectionHeading title="Meet The Team" subtitle="Team" />
          <Spacing lg="50" md="30" />
          <Section className="row">
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/member1.svg"
                name="Ömer Elibol"
                designation="Full Stack Blockchain Developer"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/member2.svg"
                name="Muzaffer Top"
                designation="Full Stack Blockchain Developer"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/ömer.jpg"
                name="Cooller458"
                designation="Full Stack Blockchain Developer"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/kutsal.jpg"
                name="Kutsal Suruç"
                designation="UI/UX Designer"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/sercan.jpg"
                name="Sercan Delibaş"
                designation="UI/UX Designer"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/zeki.jpg"
                name="Zeki Atay"
                designation="UI/UX Designer"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/yasemin.jpg"
                name="Yasemin Baran İnal"
                designation="Accounting Manager"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/aysenurısık.png"
                name="Ayşe Nur Işık"
                designation="NFT Creator"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/yılmazkayabg.png"
                name=" Yılmaz Kaya"
                designation="Associate Professor Consultant"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/elif.png"
                name="Elif Moralı"
                designation="NFT Illustrator"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/kardelen.jpeg"
                name="Kardelen Dursun"
                designation="NFT Project Coordinator"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/yasin.png"
                name="Socrates"
                designation="Full Stack Web Developer"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/züleyha.jpeg"
                name="Züleyha Güngen"
                designation="NFT Illustrator"
              />
              <Spacing lg="25" md="25" />
            </Section>
            <Section className="col-lg-3 col-sm-6">
              <Team
                src="/images/suat.png"
                name="Suat Chobani"
                designation="Marketing Lead"
              />
              <Spacing lg="25" md="25" />
            </Section>
          </Section>
        </Section>
      </Section>
      <Section tag="section" id="faq">
        <Spacing lg="95" md="70" />
        <Section className="container">
          <SectionHeading title="Still have questions?" subtitle="FAQ" />
          <Spacing lg="50" md="30" />
          <Section className="row">
            <Section className="col-lg-6">
              <Accordion
                variant=""
                data={accordionData1}
                openPosition={0}
                questionNumberStarter={1}
              />
            </Section>
            <Section className="col-lg-6">
              <Accordion
                variant=""
                data={accordionData2}
                openPosition={false}
                questionNumberStarter={5}
              />
            </Section>
          </Section>
        </Section>
      </Section>
      <Section id="contact">
        <Spacing lg="95" md="70" />
        <Section className="container">
          <SectionHeading title="Get in touch" subtitle="Contact us" />
          <Spacing lg="50" md="30" />
          <Section className="row">
            <Section className="col-lg-6">
              <ContactForm />
            </Section>
            <Section className="col-lg-5 offset-lg-1">
              <Spacing lg="30" md="40" />
              <ContactInfo />
            </Section>
          </Section>
        </Section>
        <Spacing lg="100" md="70" />
      </Section>
    </>
  );
}
