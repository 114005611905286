import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Section from "../Section";
import { isMetaMaskInstalled, connectWallet } from "../../utils/web3functions";
import { toast } from "react-toastify";
import { walletContext } from "../../contexts/walletContext";
import tokenAbi from "../../contract/token.json";
import axios from "axios";
import Web3 from "web3";
export default function ConnectCard() {
  const { setAccount } = useContext(walletContext);
  const web3 = new Web3("https://bsc-dataseed1.binance.org/");
  const handleConnectWallet = async (e) => {
    e.preventDefault();
    if (!isMetaMaskInstalled()) {
      toast.error("Metamask isn't installed!");
    } else {
      let accounts = await connectWallet();

      if (accounts.length) {
        const contract = await new web3.eth.Contract(
          tokenAbi,
          "0x1d782EC707ab99301F92337509a2B8a93C4571e0"
        );

        const tokenBalance = await contract.methods
          .balanceOf(accounts[0])
          .call();
        const bnbBalance = await web3.eth.getBalance(accounts[0]);
        const { data } = await axios.post(
          "https://api.wheelballtoken.com/api/v1/users/checkUser",
          { walletAddress: accounts[0] }
        );
        console.log(data, tokenBalance, bnbBalance);
        console.log(data, tokenBalance, bnbBalance);
        if (data.message === "ok") {
          let data = JSON.stringify({
            walletAddress: accounts[0],
            chainToken: Number(web3.utils.fromWei(`${bnbBalance}`, "ether")),
            xToken: Number(web3.utils.fromWei(`${tokenBalance}`, "ether")),
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://api.wheelballtoken.com/api/v1/users/login",
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
              console.log(error);
            });
          setAccount(accounts[0]);
          localStorage.setItem("isWalletConnected", true);
        }
        if (data.message === "ban") {
          return console.log("banlı user");
        }
        if (data.message === "register") {
          let data = JSON.stringify({
            walletAddress: accounts[0],
            chainToken: Number(web3.utils.fromWei(`${bnbBalance}`, "ether")),
            xToken: Number(web3.utils.fromWei(`${tokenBalance}`, "ether")),
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://api.wheelballtoken.com/api/v1/users/register",
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios
            .request(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
              console.log(error);
            });
          setAccount(accounts[0]);
          localStorage.setItem("isWalletConnected", true);
        }
      } else {
        toast.error("Can't connect t your metamask!");
      }
    }
  };

  return (
    <Section className="cs-wallet_secton text-center">
      <Section tag="h2" className="cs-font_22 text-uppercase cs-normal cs-m0">
        Connect Wallet
      </Section>
      <Section className="cs-height_25 cs-height_lg_25" />
      <ul className="cs-list cs-style1 cs-mp0">
        <li>
          <Link to="/" onClick={handleConnectWallet}>
            <img src="/images/metamask.svg" alt="Logo" />
          </Link>
        </li>
        {/* <li><Link to="/"><img src="/images/coinbase.svg" alt="Logo" /></Link></li>
        <li><Link to="/"><img src="/images/trustwallet.svg" alt="Logo" /></Link></li>
        <li><Link to="/"><img src="/images/walletconnect.svg" alt="Logo" /></Link></li> */}
      </ul>
      <Section className="cs-height_15 cs-height_lg_15" />
      <Section tag="p" className="cs-m0">
        Wallet are many variations of pass <br />
        ges of available.
      </Section>
    </Section>
  );
}
