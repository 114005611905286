import React from "react";
import Slider from "react-slick";
import ImageBox from "../ImageBox";
import Section from "../Section";

export default function ImageBoxSlider() {
  const data = [
    {
      src: "/images/resim_nft/karakter/kakarekter.png",
      href: "/",
      alt: "WBT",
    },
    {
      src: "/images/resim_nft/karakter/karakter2.png",
      href: "/",
      alt: "WBT",
    },
    {
      src: "/images/resim_nft/rutbe/bronz.png",
      href: "/",
      alt: "WBT",
    },
    {
      src: "/images/resim_nft/rutbe/gold.png",
      href: "/",
      alt: "WBT",
    },
    {
      src: "/images/resim_nft/token.png",
      href: "/",
      alt: "WBT",
    },
    {
      src: "/images/resim_nft/rutbe/silver.png",
      href: "/",
      alt: "WBT",
    },
    {
      src: "/images/resim_nft/rutbe/gold.png",
      href: "/",
      alt: "WBT",
    },
    {
      src: "/images/resim_nft/giris.png",
      href: "/",
      alt: "WBT",
    },
    {
      src: "/images/resim_nft/level2.png",
      href: "/",
      alt: "WBT",
    },
    {
      src: "/images/resim_nft/lose_win.png",
      href: "/",
      alt: "WBT",
    },
    {
      src: "/images/resim_nft/sonbahar.png",
      href: "/",
      alt: "WBT",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "7%",
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="cs-gap-24">
      {data.map((item, index) => (
        <Section key={index}>
          <ImageBox src={item.src} href={item.href} alt={item.alt} />
        </Section>
      ))}
    </Slider>
  );
}
