import React, { useContext, useEffect } from "react";
import { Parallax } from "react-parallax";
import parse from "html-react-parser";
import Button from "../Button";
import "./hero.scss";
import Section from "../Section";
import Modal from "../Modal";
import { walletContext } from "../../contexts/walletContext";
import CountDown from "../CountDown";
import { Link } from "react-router-dom";
import pdf from "../assets/wbt3whitepaper.md";
import axios from "axios";
import Web3 from "web3";
import tokenAbi from "../../contract/token.json";
export default function Hero({
  title,
  mintNumber,
  mintMax,
  mintPrice,
  mintDeathLine,
  bgUrl,
  heroImageUrl,
  animatedUrl,
  variant,
  bubble,
  overlay,
  bgOpacity,
}) {
  const { account } = useContext(walletContext);
  const web3 = new Web3("https://bsc-dataseed1.binance.org/");
  useEffect(() => {
    const aa = async () => {
      if (!account) return;
      console.log(account);
      const contract = await new web3.eth.Contract(
        tokenAbi,
        "0x1d782EC707ab99301F92337509a2B8a93C4571e0"
      );

      const tokenBalance = await contract.methods.balanceOf(account).call();
      const bnbBalance = await web3.eth.getBalance(account);
      console.log(Number(web3.utils.fromWei(`${bnbBalance}`, "ether")));
      console.log(Number(web3.utils.fromWei(`${tokenBalance}`, "ether")));
      let data = JSON.stringify({
        walletAddress: account,
        chainToken: Number(web3.utils.fromWei(`${bnbBalance}`, "ether")),
        xToken: Number(web3.utils.fromWei(`${tokenBalance}`, "ether")),
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.wheelballtoken.com/api/v1/users/update",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer wbtuser'
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    };
    aa();
  }, [account]);
  async function addBln() {
    window.ethereum
      .request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: "0x1d782EC707ab99301F92337509a2B8a93C4571e0",
            symbol: "WBT",
            decimals: 18,
            image: `${window.location.origin}/images/blnlogo.png`,
          },
        },
      })
      .then((success) => {
        if (success) {
          console.log("WBT successfully added to wallet!");
        } else {
          throw new Error("Something went wrong.");
        }
      })
      .catch(console.error);
  }
  return (
    <Section tag="section" className={`cs-hero cs-style1 `} id="hero">
      {overlay && (
        <Section className="cs-dark_overlay" style={{ opacity: overlay }} />
      )}
      <Section className="cs-hero_bg" style={{ opacity: bgOpacity }}>
        <Parallax bgImage={bgUrl} bgImageAlt="Hero" strength={120}></Parallax>
      </Section>
      <Section className="container">
        <Section className="cs-hero_text">
          <Section
            tag="h2"
            className="cs-hero_secondary_title cs-font_24 cs-font_18_sm"
          >
            <span style={{ color: "#EEB700" }}> Seed Sale</span>
          </Section>
          <Section
            tag="h1"
            className="cs-hero_title cs-font_64 cs-font_36_sm cs-bold"
          >
            {parse(title)}
          </Section>
          <Section className="cs-btn_group">
            {account !== "" ? (
              <Modal modalType="mint" btnText="Buy WBT" />
            ) : (
              <Modal modalType="connect" btnText="Connect" btnIcon />
            )}
                       <div
              onClick={addBln}
              className="cs-btn cs-color1"
              style={{ cursor: "pointer" }}
            >
              Add WBT
            </div>
            <Link to="https://ramp.network/buy">
              <div className="cs-btn cs-color1" style={{ cursor: "pointer" }}>
                Add BNB
              </div>
            </Link>
          </Section>
          <Section
            tag="h3"
            className="cs-hero_subtitle cs-font_18 cs-font_16_sm cs-body_line_height"
          >
            Per WBT Price
            <Section tag="span" className="cs-accent_color">
              {" "}
              {mintPrice}
            </Section>{" "}
            $ <br />
            First Sale End
            <Section tag="span" className="cs-accent_color">
              {" "}
              {mintDeathLine}
            </Section>
          </Section>
        </Section>

        <Section className="cs-hero_img">
          <div>
            <CountDown day={1} month={1} title="Token Sell Start In" />
          </div>
        </Section>
      </Section>

      {bubble && (
        <Section id="background-wrap">
          <Section className="bubble x1"></Section>
          <Section className="bubble x2"></Section>
          <Section className="bubble x3"></Section>
          <Section className="bubble x4"></Section>
          <Section className="bubble x5"></Section>
          <Section className="bubble x6"></Section>
          <Section className="bubble x7"></Section>
          <Section className="bubble x8"></Section>
          <Section className="bubble x9"></Section>
          <Section className="bubble x10"></Section>
        </Section>
      )}
    </Section>
  );
}
