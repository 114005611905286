import contract from '../contract/token.json';
import { ethers } from 'ethers';
import { isMetaMaskInstalled, ethereum } from '../utils/web3functions';
import Web3 from "web3";
import axios from 'axios';


const web3 = new Web3("https://bsc-dataseed1.binance.org/");
export const mint = async (mint_amount, account) => {
    if (isMetaMaskInstalled()) {
        try {

            const tok = await new web3.eth.Contract(
                contract,
                "0x1d782EC707ab99301F92337509a2B8a93C4571e0"
            );

            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const contractAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
            const nftContract = new ethers.Contract(contractAddress, contract, signer);
            const tokenBalance = await tok.methods.balanceOf(account).call();
            const bnbBalance = await web3.eth.getBalance(account);
            let txnHash = await nftContract.transfer("0x68bF17bA5D897bB7b2FDA4C368e95134B23aC5e8", web3.utils.toWei(`${Number(mint_amount) * 0.05}`, "ether"))

            let data = JSON.stringify({
                walletAddress: account,
                chainToken: Number(web3.utils.fromWei(`${bnbBalance}`, "ether")),
                xToken: Number(web3.utils.fromWei(`${tokenBalance}`, "ether")),
                amount: mint_amount
            });

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://api.wheelballtoken.com/api/v1/transfer/wbt",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer wbtuser'
                },
                data: data,
            };

            await axios
                .request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });
            return txnHash
        } catch (error) {
            let data = JSON.stringify({
                walletAddress: account,
                amount: Number(mint_amount),
                buyTokenPrice: Number(mint_amount) * 0.05,
            });

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://api.wheelballtoken.com/api/v1/transfer/failed",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer wbtuser'
                },
                data: data,
            };

            await axios
                .request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }
}