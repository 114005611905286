import { ethers } from 'ethers';
import { isMetaMaskInstalled, ethereum } from '../utils/web3functions';
import axios from 'axios';
import Web3 from "web3";
import contract from '../contract/token.json';

const web3 = new Web3("https://bsc-dataseed1.binance.org/");

export const bnbPayment = async (weiValue, account, counter) => {
  if (isMetaMaskInstalled()) {
    try {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const tok = await new web3.eth.Contract(
        contract,
        "0x1d782EC707ab99301F92337509a2B8a93C4571e0"
    );

    const contractAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
    const nftContract = new ethers.Contract(contractAddress, contract, signer);
    const tokenBalance = await tok.methods.balanceOf(account).call();
    const bnbBalance = await web3.eth.getBalance(account);

      // Alıcı adresi ve gönderilecek BNB miktarını belirle
      const recipientAddress = "0xce17DfB74E2C364943085e17B254dEA576e98F96"; // Örnek alıcı adresi
      const bnbAmount = ((weiValue) ).toString(); // Gönderilecek BNB miktarını Ether cinsinden belirle

    const txn = await signer.sendTransaction({
        from: account,
        to: recipientAddress,
        value: bnbAmount,
        gasLimit:200000
      });
    

    console.log('BNB Payment Hash:', txn.hash);
    

      // İşlem sonrası API isteği
      let data = JSON.stringify({
        walletAddress: account,
        chainToken: Number(web3.utils.fromWei(`${bnbBalance}`, "ether")),
        xToken: Number(web3.utils.fromWei(`${tokenBalance}`, "ether")),
        amount: counter
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.wheelballtoken.com/api/v1/transfer/wbt",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer yourtoken'
        },
        data: data,
      };

      await axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

      return txn.hash; // İşlem hash'ini dön
    } catch (error) {
      console.error('BNB Payment Error:', error);

      // Hata durumunda API isteği
      let errorData = JSON.stringify({
        walletAddress: account,
        amount: weiValue,
        errorMessage: error.message
      });

      let errorConfig = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.wheelballtoken.com/api/v1/payment/failed",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer yourtoken'
        },
        data: errorData,
      };

      await axios.request(errorConfig)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  } else {
    console.error('MetaMask is not installed!');
  }
}
