import { Icon } from "@iconify/react";
import React from "react";
import Slider from "react-slick";
import Section from "../Section";
import CardStype2 from "../Card/CardStype2";

export default function RoadmapSlider() {
  const data = [
    
      {
        chekc: false,
        phase: "Q1 2024",
        title: "Launch Seed Sale",
        subtitle:["Beta Release of Wheel Ball Game App Store and Google Play Store"]
          
      },
    
    {
      chekc: false,
      phase: "Q4 2024",
      title: "Beta Release of NFT Marketplace",
      subtitle:
        ["Official Launch of Wheel Ball Game.","Official Launch of NFT Marketplace","Private Sale.","RPG Game Intro",]
    },
    {
      chekc: false,
      phase: "Q1 2025",
      title: "Listing",
      subtitle:
        ["Public Sale","Defi Listing","Cex Listing (Tier 2)","NFT Marketplace Mainnet Launch in London",]
    },
    {
      chekc: false,
      phase: "Q3 2025",
      title: "RGP Game",
      subtitle:
        ["Cex Listing (Tier 1)","Beta Release of RGP Game"],
    },
    {
      chekc: false,
      phase: "Q4 2025",
      title: "Wheel Finance",
      subtitle:
        ["NFT Marketplace Mobile","AI-NFT Createor and Game Design"],
    },
  ];
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="material-symbols:chevron-left-rounded" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="material-symbols:chevron-right-rounded" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1285,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="cs-gap-24 cs-awwow_style_1 cs-type1">
      {data.map((item, index) => (
        <Section key={index}>
          <CardStype2
            cardNumber={index + 1}
            phase={item.phase}
            title={item.title}
            subtitle={item.subtitle}
            check={item.chekc}
          />
        </Section>
      ))}
    </Slider>
  );
}
