import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import Section from "../Section";
import { mint } from "../../utils/web3mint";
import { bnbPayment } from "../../utils/web3BNBmint";
import { toast } from "react-toastify";
import { walletContext } from "../../contexts/walletContext";
import Spacing from "../Spacing";
import axios from "axios";
import Web3 from "web3";
import DotLoader from "react-spinners/PuffLoader";
import tokenAbi from "../../contract/token.json";
import "./index.css";
export default function MintCard() {
  const { account } = useContext(walletContext);
  const [counter, setCounter] = useState(1);
  const [price, setPrice] = useState(0.05);
  const [coins, setCoins] = useState([]);
  const [coins1, setCoins1] = useState([]);
  const [coins2, setCoins2] = useState([]);
  const [loading, setLoading] = useState(false);
  const web3 = new Web3("https://bsc-dataseed1.binance.org/");
  useEffect(() => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/steem?tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false"
      )
      .then((res) => {
        setCoins(res.data.market_data.current_price.usd);
      })
      .catch((error) => console.log(error));
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/steem-dollars?tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false"
      )
      .then((res) => {
        setCoins1(res.data.market_data.current_price.usd);
      })
      .catch((error) => console.log(error));
      axios
      .get("https://api.binance.com/api/v3/ticker/price")
      .then((res) => {
        const BNBPrice = res.data.filter(
          (market) => market.symbol === "BNBUSDT"
        )[0].price;
        setCoins1(BNBPrice);
      })
      .catch((error) => console.log(error));
      console.log(coins1, "coinscoins");
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/binance-usd?tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false"
      )
      .then((res) => {
        setCoins2(res.data.market_data.current_price.usd);
      })
      .catch((error) => console.log(error));
  }, []);
  const { setMint } = useContext(walletContext);
  const handelSubtract = () => {
    if (counter > 1) {
      setCounter(counter - 1);
    }
  };
  const handleToken = (e) => {
    if (e.target.value < 0) {
      setCounter(0);
    }
    setCounter(e.target.value);
  };
  const handleMint = async () => {
    const contract = await new web3.eth.Contract(
      tokenAbi,
      "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
    );

    const tokenBalance = await contract.methods.balanceOf(account).call();
    const bnbBalance = await web3.eth.getBalance(account);
    if (
      Number(web3.utils.fromWei(`${tokenBalance}`, "ether")) <
      Number(counter) * 0.05
    ) {
      return toast.error("Insufficient balance");
    }
    setLoading(true);
    try {
      let txn = await mint(counter, account);
      console.log(txn, "naber");
      if (txn.hash.length > 0) {
        toast.success("Buying Successfuly", {
          position: toast.POSITION.TOP_CENTER,
        });
        setMint((mint) => !mint);
        setLoading(false);
      }
      if (!txn) {
        setLoading(false);
        setMint((mint) => !mint);
        toast.error("Buying Failed", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (!txn.hash.length) {
        console.log("burda", "naber");
        setLoading(false);
        setMint((mint) => !mint);
        toast.error("Buying Failed", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setLoading(false);

      toast.error("Buying Failed", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const handleMintBNB = async () => {
    
    const bnbBalance = await web3.eth.getBalance(account);
    
    setLoading(true);

    try {
      const bnbPrice = coins1; // BNB'nin güncel fiyatı
      console.log(coins1, "bnbPrice");
      const requiredBNB = ((Number(counter) * ((0.05) / Number(bnbPrice)))); 
      const requiredWei = web3.utils.toWei(requiredBNB.toString(), "ether"); 
      console.log(requiredBNB, "bnbAmount");
      console.log(requiredWei, "weiValue");
      const txnHash = await bnbPayment(requiredWei, account, counter);

      if (txnHash) {
        toast.success("BNB Payment Successful", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("BNB Payment Failed", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error("BNB Payment Failed", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setLoading(false);
      setMint((mint) => !mint);
    }
  };
  return (
    <Section className="cs-mint_secton">
      <p className="cs-m0 text-center ">Buy WBT</p>
      <ul className="cs-list cs-style2 cs-mp0 cs-primary_color cs-primary_font">
        <li>
          <Section className="cs-list_left col-lg-5">
            Quantity / <span className="cs-accent_color">0.05 $</span>
          </Section>
          <Section className="cs-list_right">

            <Section className="col-lg-10">
              <div style={{ height: "10px", marginTop: "20px" }}></div>
              <Section className="cs-form_field_wrap">
                <input
                  type="number"
                  className="cs-form_field"
                  onChange={(e) => handleToken(e)}
                />
              </Section>
              <Spacing lg="30" md="30" />
            </Section>
          </Section>
        </li>
        <li>
          <Section className="cs-list_left">Total Price</Section>
          <Section className="cs-list_right">{price * counter} $</Section>
        </li>
      </ul>
      <Section className="cs-height_25 cs-height_lg_25" />

      <button
        onClick={handleMint}
        className="cs-btn cs-btn_filed cs-accent_btn text-center text-uppercase w-100"
        disabled={loading}
      >
        {loading ? (
          <DotLoader color="#1876f2" loading={loading} size={30} />
        ) : (
          <span>Buy with BUSD</span>
        )}
      </button>
      <Section className="cs-button-spacing" />
      <button
        onClick={handleMintBNB}
        className="cs-btn cs-btn_filed cs-accent_btn text-center text-uppercase w-100"
        disabled={loading}
      >
        {loading ? (
          <DotLoader color="#1876f2" loading={loading} size={30} />
        ) : (
          <span>Buy with BNB</span>
        )}
      </button>
      <Section className="cs-height_15 cs-height_lg_15" />
      <Section tag="p" className="cs-m0 text-center">
        {/* Presale &amp; whitelist : Soldout */}
      </Section>
    </Section>
  );
}
